import groupBy from 'lodash/groupBy'

const { api } = useFeathers()
const { snackbarAction } = useSnackbar()

export const useCartStore = defineStore({
  id: 'cart',
  state: () => ({
    activeTab: 'billing',
    billing: {
      BillName: undefined,
      BillAddress1: undefined,
      BillAddress2: undefined,
      BillAddress3: undefined,
      BillCity: undefined,
      BillState: undefined,
      BillZip: undefined,
    },
    cartDrawer: false,
    cartItems: [] as any[],
    cartOrder: null as any,
    initialized: false,
    shipping: {
      items: {
        ShipName: undefined,
        ShipAddress1: undefined,
        ShipAddress2: undefined,
        ShipAddress3: undefined,
        ShipCity: undefined,
        ShipState: undefined,
        ShipZip: undefined,
      },
      samples: {
        ShipName: undefined,
        ShipAddress1: undefined,
        ShipAddress2: undefined,
        ShipAddress3: undefined,
        ShipCity: undefined,
        ShipState: undefined,
        ShipZip: undefined,
      },
      tests: {
        ShipName: undefined,
        ShipAddress1: undefined,
        ShipAddress2: undefined,
        ShipAddress3: undefined,
        ShipCity: undefined,
        ShipState: undefined,
        ShipZip: undefined,
      },
    } as Record<string, Record<string, undefined | string>>,
    useDefaults: {
      billing: false,
      items: false,
      samples: false,
      tests: false,
    } as Record<string, boolean>,
    valid: {
      billing: false,
      items: false,
      samples: false,
      tests: false,
    } as Record<string, boolean>,
  }),
  getters: {
    cartByProducts(state) {
      return groupBy(state.cartItems || [], 'Product.ID')
    },
    election(state) {
      return state.cartItems.length ? state.cartOrder?.Election || null : null
    },
    expeditedShippingCost(state) {
      const showEstimatedCost = this.showEstimatedCost as boolean
      const items = state.cartItems.filter(item => item.TestQuantity > 0 || item.Description.includes('Test Decks'))
      return items.length > 0 && showEstimatedCost ? 30 : 0
    },
    isElectrack(state) {
      return state.cartOrder?.Electrack || Object.keys(groupBy(state.cartItems || [], 'Product.FileType')).includes('MABS')
    },
    isValid(state) {
      const show = this.showShipping as Record<string, boolean>
      const tabs: Record<string, boolean> = {
        billing: state.valid.billing,
        shipping: (!show?.items || state.valid.items) && (!show?.samples || state.valid.samples) && (!show?.tests || state.valid.tests),
      }
      return tabs?.[state.activeTab] || false
    },
    orderId(state) {
      return state.cartOrder?.ID || null
    },
    showEstimatedCost() {
      const election = this.election as any
      return ['School', 'Village', 'Special'].includes(election?.Type || '')
    },
    showShipping(state) {
      return state.cartItems.reduce((acc, item) => {
        if (item.ItemQuantity > 0)
          acc.items = true
        if (item.SampleQuantity > 0)
          acc.samples = true
        if (item.TestQuantity > 0)
          acc.tests = true
        return acc
      }, { items: false, samples: false, tests: false })
    },
    totalEstimatedCost(state) {
      const expeditedShippingCost = this.expeditedShippingCost as number
      return state.cartItems.reduce((acc, val) => acc + val.TotalCost, 0) + expeditedShippingCost
    },
  },
  actions: {
    async initNewCart() {
      const userID = useAuthStore()?.user?.ID || null
      const customerAccount = useAuthStore()?.user?.SelectedCustomerAccount || null
      await api.service('pge/orders').create({
        CustomerAccount: customerAccount,
        ElectionID: 0,
        Status: 'In Cart',
        Cart: true,
        CreatedBy: userID,
      } as any)
      this.$patch({ initialized: true })
    },
    async removeItem(id: number) {
      const errorMsg = `Failed to removing product`
      const successMsg = `Product removed from cart`
      await snackbarAction(async () => {
        await api.service('pge/orderProducts').remove(id)
      }, successMsg, errorMsg)
    },
    async submitOrder() {
      const errorMsg = `Failed to submitting order`
      const successMsg = `Order submitted`
      await snackbarAction(async () => {
        const order = this.cartOrder.clone()
        order.Billing = this.billing
        order.Cart = false
        order.Electrack = this.isElectrack
        order.ElectrackStatus = this.isElectrack ? 'Not Started' : null
        order.Shipping = this.shipping
        order.Status = 'Processing'
        await order.save()

        api.service('cc/epmsOrder').create({ Message: { CreateOrder: true, ID: this.orderId, SendConfirmation: true } })
        api.service('pge/files').patch(null, { ImportEsko: true }, {
          query: { OrderID: this.orderId },
        })
        this.$reset()
        this.initNewCart()
      }, successMsg, errorMsg)
    },
  },
})
